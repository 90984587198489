.modal-wrapper {
}

.modal-wrapper .modal-content {
  padding: 20px;
  background-color: white;
  height: 622px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 16px;
  justify-content: space-around;
}

.modal-wrapper .modal-content .title-wrapper {
  text-align: center;
}

.modal-wrapper .modal-content .title-wrapper .title {
  font-size: 32px;
  font-weight: 300;
}

.modal-wrapper .modal-content .title-wrapper .sub-title {
  font-size: 16px;
  color: #797979;
  font-weight: 300;
}

.modal-wrapper .modal-content .btns-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-wrapper .modal-content .btns-wrapper .send-btn {
  border-radius: 13px;
  background-color: #42bfb4;
  min-width: 280px;
  color: white;
  font-size: 16px;
  padding: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modal-wrapper .modal-content .btns-wrapper .cancel-btn {
  color: #42bfb4;
  font-size: 14px;
  font-weight: 500;
  padding: 5px;
  cursor: pointer;
  margin-top: 15px;
}

.modal-wrapper .modal-content .input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.modal-wrapper .modal-content .input-wrapper .eye-icon {
  position: absolute;
  right: 12px;
  top: 52px;
  cursor: pointer;
}

.modal-wrapper .modal-content .input-wrapper .inner-wrapper {
  position: relative;
}

.modal-wrapper .modal-content .input-wrapper .inner-wrapper .error-icon {
  position: absolute;
  right: 10px;
  top: 15px;
}

.modal-wrapper .modal-content .input-wrapper .error-msg {
  font-size: 16px;
  color: #b44d4d;
  text-align: center;
  margin-top: 10px;
}

.modal-wrapper .modal-content .input-wrapper .input {
  padding: 15px;
  width: 250px;
  border-radius: 8px;
  border: solid 1px #e8e8e9;
  text-align: center;
  font-size: 16px;
}

.modal-wrapper .modal-content .input-wrapper input.invalid {
  border: 1px solid red;
}

.modal-wrapper .modal-content .input-wrapper .input-label {
  color: #393939;
  font-size: 16px;
  margin-bottom: 10px;
}

.modal-wrapper .modal-content .input-wrapper .input:focus {
  outline: none;
  text-align: center;
  color: black;
  border: 1px solid #42bfb4;
}

.modal-wrapper .modal-content .input-wrapper .input::placeholder {
  text-align: center;
  color: #797979;
  font-size: 18px;
}


.confirm-modal-wrapper {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  margin-top: 50px;
}

.confirm-modal-wrapper .modal-content {
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  height: 350px;
  width: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.26);
  border-radius: 16px;
  justify-content: space-around;
}

.confirm-modal-wrapper .modal-content .title {
  color: black;
  font-size: 32px;
  font-weight: 300;
}

.confirm-modal-wrapper .modal-content .subtitle {
  font-size: 16px;
  font-weight: 300;
}

.confirm-modal-wrapper .modal-content .button {
  border-radius: 13px;
  background-color: #42bfb4;
  min-width: 280px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 12px;
}

.confirm-modal-wrapper .modal-content .alt-button {
  color: #42bfb4;
  font-size: 14px;
  cursor: pointer;
}

.confirm-modal-wrapper.newPassword .modal-content {
  width: 360px;
}

.confirm-modal-wrapper.newPassword .modal-content .title {
  font-size: 24px;
}
