.close-button {
  position: absolute;
  cursor: pointer;
  width: 33px;
  height: 33px;
  font-size: 14px;
  color: #141414;
  background-color: white !important;
  justify-content: center;
  align-items: center;
  top: 15px;
  right: 10px;
  display: flex;
}
