.language-selector {
    font-family: 'Heebo', sans-serif;
}

.language-selector .selector {
    width: 33px;
    height: 33px;
    font-size: 14px;
    color: #141414;
    background-color: white !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    text-transform: uppercase;
    position: fixed;
    top: 15px;
    left: 25px;
}

.language-selector .selector .selector-icon {
    position: absolute;
    top: 8px;
    left: -15px;
}

.language-selector .lang-list {
    position: fixed;
    top: 55px;
    left: 5px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.language-selector .lang-list .lang {
    padding: 4px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.language-selector .lang-list .lang .txt {
    font-size: 22px;
    color: #8d8d8d;
    text-transform: uppercase;
}

.language-selector .lang-list .lang .icon {
    position: absolute;
    top: 10px;
    left: 11px;
}

.language-selector .lang-list .lang.selected {
    cursor: default;
}

.language-selector .lang-list .lang.selected .txt {
    color: #141414;
}

.language-selector .lang-list .sep {
    height: 1px;
    opacity: 0.74;
    background-color: #cdcdcd;
    margin: 0 10px;
    border-radius: 50%;
}


.language-selector.modal .selector {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 28px;
    height: 28px;
    font-size: 13px;
}

.language-selector.modal .selector-icon {
    top: 5px;
}

.language-selector.modal .lang-list {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 50px;
    width: 80px;
    height: 70px;
}

.language-selector.modal .lang {
    padding: 5px;
}

.language-selector.modal .lang .txt {
    font-size: 16px;
}

.language-selector.modal .lang.selected .icon {
    top: 7px;
    left: 7px;
}

.language-selector.modal .lang.selected .icon svg {
    width: 15px;
    height: 15px;
}
