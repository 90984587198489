.gip-modal-wrapper {
}

.gip-modal-wrapper .gip-modal-content {
  min-height: 620px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 16px;
  color: black;
  position: relative;
  justify-content: center;
  max-width: 360px;
  margin: auto;
}

.gip-modal-wrapper .gip-modal-content .title {
  font-size: 20px;
  margin-bottom: 10px;
}

.gip-modal-wrapper .gip-modal-content .options-wrapper .option {
  position: relative;
  border-radius: 25px;
  border: solid 1px #8b8b8b;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  background-color: white;
  min-width: 280px;
  padding: 10px 0;
  margin: 10px 0;
}

.gip-modal-wrapper .gip-modal-content .options-wrapper .option .icon {
  position: absolute;
  left: 15px;
}

.gip-modal-wrapper .gip-modal-content .options-wrapper .option .icon.sso {
  top: 6px;
}

.gip-modal-wrapper .gip-modal-content .options-wrapper .option:hover {
  background-color: #f5f5f5;
}

.gip-modal-wrapper .gip-modal-content .hr-title {
  font-size: 16px;
  text-align: center;
  display: table;
  white-space: nowrap;
  color: #141414;
  margin: 10px 30px;
}

.gip-modal-wrapper .gip-modal-content .hr-title:before, .gip-modal-wrapper .gip-modal-content .hr-title:after {
  border-top: 1px solid #707070;
  content: '';
  display: table-cell;
  position: relative;
  top: 0.7em;
  width: 50%;
}

.gip-modal-wrapper .gip-modal-content .hr-title:before {
  right: 7%;
}

.gip-modal-wrapper .gip-modal-content .hr-title:after {
  left: 7%;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper {
  display: flex;
  flex-direction: column;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper.heb {
  direction: rtl;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper.heb .input-wrapper .input-label {
  left: auto;
  right: 10px;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper.heb .input-wrapper .input-floating-label {
  left: auto;
  right: 18px;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper.heb .input-wrapper .error-message {
  left: auto;
  right: 5px;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper.heb .input-wrapper .eye-icon {
  left: 15px;
  right: auto;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper .input-wrapper {
  position: relative;
  margin: 15px 0;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper .input-wrapper .eye-icon {
  position: absolute;
  right: 15px;
  top: 17px;
  cursor: pointer;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper .input-wrapper input {
  border-radius: 7px;
  width: 250px;
  max-height: 55px !important;
  border: solid 1px #d9d9d9;
  padding: 15px;
  font-size: 16px;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper .input-wrapper input:focus {
  outline: none !important;
  border: 1px solid #42bfb4;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper .input-wrapper input:-webkit-autofill {
  -webkit-background-clip: text;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper .input-wrapper .input-label {
  position: absolute;
  left: 10px;
  font-size: 10px;
  top: -7px;
  background-color: white;
  padding: 0 5px;
  visibility: hidden;
  color: #42bfb4;
  text-transform: capitalize;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper .input-wrapper .input-floating-label {
  position: absolute;
  font-size: 16px;
  top: 29%;
  left: 18px;
  pointer-events: none;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper .input-wrapper.hasValue .input-floating-label {
  visibility: hidden;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper .input-wrapper.hasValue .input-label {
  visibility: visible;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper .input-wrapper.hasValue input {
  border: 1px solid #42bfb4;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper .input-wrapper input.invalid {
  border: 1px solid red;
}

.gip-modal-wrapper .gip-modal-content .form-wrapper .input-wrapper .error-message {
  color: red;
  font-size: 10px;
  position: absolute;
  padding: 3px;
  font-weight: 500;
  left: 5px;
}

.gip-modal-wrapper .gip-modal-content .btn-wrapper {
  margin: 10px auto 0;
}

.gip-modal-wrapper .gip-modal-content .btn-wrapper .action-btn {
  border-radius: 11px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  background-image: linear-gradient(to left, #42bfb4, #2bdca6);
  min-width: 280px;
  display: flex;
  justify-content: center;
  padding: 12px;
  cursor: pointer;
}

.gip-modal-wrapper .gip-modal-content .forgot-password {
  color: #42bfb4;
  font-weight: 500;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;
}

.toggle-switch {
  display: flex;
  padding: 4px;
  align-items: center;
  align-self: stretch;
  border-radius: 100px;
  border: 1px solid #42bfb4;
  background: rgba(0, 48, 87, 0.04);
  max-height: 48px;
}

.toggle-option {
  white-space: nowrap;
  width: 50%;
  text-align: center;
  cursor: pointer;
  border-radius: 100px;
  background: none;
  transition: background-color 0.3s, color 0.3s;
  color: grey;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px; 
}

.toggle-option.active {
  background: linear-gradient(0deg, rgba(66, 192, 180, 0.24) 0%, rgba(66, 192, 180, 0.24) 100%), #FFF;
  color: #42C0B4;
}

.toggle-option:not(.active) {
  background: none;
  color: grey;
}
