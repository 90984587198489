.gender-selector {
    font-family: 'Heebo', sans-serif;
    position: relative;
    display: flex;
}

.gender-selector .gender {
    border: none;
    background-color: #89d2c7;
    width: 125px;
    height: 60px;
    border-radius: 11px;
    outline: none;
    margin: 0 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
}

.gender-selector .gender.lg {
    width: 245px;
}

.gender-selector .gender.selected {
    background-color: white;
}

.gender-selector .gender .value {
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #5a5a5a;
}

@media (min-width: 481px) and (max-width: 767px) {
    .gender-selector .gender {
        width: 80px !important;
        height: 45px !important;
        margin: 0 6px;
    }

    .gender-selector .gender.lg {
        width: 160px !important;
    }

    .gender-selector .gender .value {
        font-size: 16px;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .gender-selector .gender {
        width: 62px !important;
        height: 45px !important;
        margin: 0 6px;
    }

    .gender-selector .gender.lg {
        width: 160px !important;
    }

    .gender-selector .gender .value {
        font-size: 16px;
    }
}
