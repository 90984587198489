.otp-prompt-top {
}

.otp-prompt-top .prompt-content .send-otp-btn-wrapper .action-btn {
  border-radius: 11px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  background-image: linear-gradient(to left, #42bfb4, #2bdca6);
  min-width: 280px;
  display: flex;
  justify-content: center;
  padding: 12px;
  cursor: pointer;
  border: none;
}

.otp-prompt-top .prompt-content .send-otp-btn-wrapper .action-btn.disabled {
  background: var(--light-action-disabled-background-12-p, rgba(0, 0, 0, 0.12));
  color: var(--light-action-disabled-26-p, rgba(0, 0, 0, 0.26));
  cursor: not-allowed;
}

.otp-prompt-top .prompt-content  {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  top: 180px;
  height: 60%;
  width: 300px;
  right: 0;
  text-align: center;
  position: absolute;
  padding: 21px;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;

  z-index: 5 ;
}

.otp-prompt-top .prompt-content .input-wrapper {
  position: relative;
  margin: 12px 0;
}

.otp-prompt-top .prompt-content .input-wrapper input{
  border-radius: 7px;
  width: 250px;
  max-height: 55px !important;
  border: solid 1px #d9d9d9;
  padding: 15px;
  font-size: 16px;
}

.otp-prompt-top .prompt-content .title {
  font-size: 20px;
  margin-bottom: 10px;
}

.otp-prompt-top .prompt-content .input-wrapper {
  position: relative;
  margin: 20px 0;
}

.otp-prompt-top .prompt-content .input-wrapper input {
  border-radius: 7px;
  width: 250px;
  max-height: 55px !important;
  border: solid 1px #d9d9d9;
  padding: 15px;
  font-size: 16px;
}

.otp-prompt-top .prompt-content .input-wrapper input:focus {
  outline: none !important;
  border: 1px solid #42bfb4;
}

.otp-prompt-top .prompt-content .input-wrapper input:-webkit-autofill {
  -webkit-background-clip: text;
}

.otp-prompt-top .prompt-content .input-wrapper .input-label {
  position: absolute;
  left: 10px;
  font-size: 10px;
  top: -7px;
  background-color: white;
  padding: 0 5px;
  visibility: hidden;
  color: #42bfb4;
  text-transform: capitalize;
}

.otp-prompt-top .prompt-content .input-wrapper .input-floating-label {
  position: absolute;
  font-size: 16px;
  top: 29%;
  left: 18px;
  pointer-events: none;
}

.otp-prompt-top .prompt-content .input-wrapper.hasValue .input-floating-label {
  visibility: hidden;
}

.otp-prompt-top .prompt-content .input-wrapper.hasValue .input-label {
  visibility: visible;
}

.otp-prompt-top .prompt-content .input-wrapper.hasValue input {
  border: 1px solid #42bfb4;
}

.otp-prompt-top .prompt-content .input-wrapper input.invalid {
  border: 1px solid red;
}

.otp-prompt-top .prompt-content .input-wrapper .error-message {
  color: red;
  font-size: 10px;
  position: absolute;
  padding: 3px;
  font-weight: 500;
  left: 5px;
}

.otp-prompt-top .prompt-content .send-otp-btn-wrapper {
  margin: 10px auto 0;
}


.resend-message-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.resend-message-wrapper .icon {
  text-align: center;
}

.resend-message-wrapper .icon svg {
  display: block;
  margin: 0 auto;
}

.resend-message-wrapper .text {
  text-align: center;
}

.resend-message-wrapper .primary-text {
  color:  rgba(0, 0, 0, 0.87);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; 
  letter-spacing: 0.15px;
  margin-bottom: 8px;
}

.resend-message-wrapper .second-text {
  color:  rgba(0, 0, 0, 0.60);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; 
  letter-spacing: 0.17px;
  margin-bottom: 8px;
}
.error-message{
  color: #D32F2F;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%; 
  letter-spacing: 0.4px;display: flex;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}

.resend-otp-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 16px 25px 0px 0px;
}

.didnt-get-code-text {
  color:  rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.countdown-timer {
  color:  rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
  flex: 1;
  text-align: center;
}

.resend-btn {
  color: rgba(0, 0, 0, 0.26);
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.46px;
  cursor: default;
  flex: 1;
  text-align: right;
}

.resend-btn.clickable {
  color: #42C0B4;
  cursor: pointer;
}


.method-selection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  align-self: stretch;
}

.radio-btn {
  display: flex;
  align-items: center;
  position: relative;
}

.radio-btn input[type="radio"] {
  width: auto !important;
  margin: 0;
  opacity: 0; 
  position: absolute;
  z-index: -1;
}

.radio-btn label {
  display: flex;
  align-items: center;
  padding-left: 24px; 
  cursor: pointer;
  position: relative;
}

.radio-btn label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #666666;
  border-radius: 50%;
  background-color: white;
  box-sizing: border-box;
}

.radio-btn input[type="radio"]:checked + label::before {
  border-color: #42bfb4;
  background-color: #42bfb4;
  box-shadow: inset 0 0 0 4px white;
}

.radio-btn label span {
  margin-left: 8px; 
}

.phone-input-wrapper{
  direction: ltr !important;
  display: flex;
}

.react-tel-input .form-control{
  height: 40px !important;
  position: absolute;
  left: 20px;
} 

.react-tel-input .selected-flag{
  height: 38px !important;
  background-color: #fff;
}

.react-tel-input .country-list{
  width: 300px !important;
  top: 50px !important;
  max-height: 300px !important;
}


.grecaptcha-badge{
  z-index: 999 !important;
}