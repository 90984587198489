.date-selector {
    font-family: 'Heebo', sans-serif;
    position: relative;
    display: flex;
}

.date-selector .field-select .select {
    border: none;
    background-color: #89d2c7;
    width: 150px;
    height: 60px;
    border-radius: 11px;
    outline: none;
    margin: 0 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
}

.date-selector .field-select .select.selected {
    background-color: white;
}

.date-selector .field-select .select .value {
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #5a5a5a;
}

.date-selector .field-select .select .v-select {
    position: absolute;
    top: 15px;
    right: 10px;
    cursor: pointer;
}

.date-selector .field-select .select .options {
    position: absolute;
    top: 65px;
    left: 0;
    width: 85px;
    max-height: 160px;
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.date-selector .field-select .select .options .options-container {
    position: relative;
    overflow: hidden;
    height: 160px;
    width: 100%;
    border-radius: 15px;
    background-color: white;
    z-index: 9;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.date-selector .field-select .select .options .options-container .inner-container {
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    position: absolute;
    right: -7px;
}

.date-selector .field-select .select .options .options-container .inner-container .fix-cont {
    position: absolute;
    top: 0;
    left: -4px;
    width: 100%;
    z-index: 9;
}

.date-selector .field-select .select .options .options-container .inner-container .fix-cont .option {
    font-size: 18px;
    color: #5a5a5a;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.date-selector .field-select .select .options .options-container .inner-container .fix-cont .sep {
    padding: 1px;
    opacity: 0.74;
    background-color: #154d59;
    margin: 0 5px;
    border-radius: 50%;
}

.date-selector .field-select.month .select {
    width: 150px;
}

.date-selector .field-select.month .select .options {
    width: 150px;
}

.date-selector .field-select.year .select .options {
    width: 150px;
}

.date-selector .field-select.day .select .options {
    width: 100px;
}

@media (min-width: 481px) and (max-width: 767px) {
    .date-selector .field-select .select {
        width: 60px;
        height: 45px;
        margin: 0 6px;
    }

    .date-selector .field-select .select .value {
        font-size: 16px;
    }

    .date-selector .field-select .select .v-select {
        top: 9px;
        right: 5px;
    }

    .date-selector .field-select .select .options {
        top: 49px;
        left: 0;
        width: 60px;
    }

    .date-selector .field-select .select .options .options-container .inner-container .fix-cont .option {
        font-size: 16px;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .date-selector .field-select .select {
        width: 60px;
        height: 45px;
        margin: 0 6px;
    }

    .date-selector .field-select .select .value {
        font-size: 16px;
    }

    .date-selector .field-select .select .v-select {
        top: 9px;
        right: 5px;
    }

    .date-selector .field-select .select .options {
        top: 49px;
        left: 0;
        width: 60px;
    }

    .date-selector .field-select .select .options .options-container .inner-container .fix-cont .option {
        font-size: 16px;
    }

    .date-selector .field-select.month .select {
        width: 90px;
    }

    .date-selector .field-select.month .select .options {
        width: 90px;
    }

    .date-selector .field-select.year .select {
      font-size: 20px;
      height: 42px;
      width: 146px;
    }

    .date-selector .field-select.year .select .options {
        width: 70px;
    }
}
