
.patient-onboard-wizard-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-content > div {
  background-size: 100% auto;
  background-repeat: repeat;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 78%, #2e8b8b), url('https://media.wizecare.com/public_assets/patient-onboard-bg.png');
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body {
  height: 100%;
  position: relative;
  border-bottom: none !important;
  overflow: auto;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .lang-btn-wrap {
  position: absolute;
  top: 15px;
  right: 25px;
  z-index: 1600;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .back-btn-wrap {
  cursor: pointer;
  position: absolute;
  top: 15px;
  left: 25px;
  z-index: 1600;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .stepper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .stepper.keyboard-open {
  bottom: -1%;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .wc-logo {
  position: absolute;
  bottom: 0;
  left: 20px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step {
  padding-top: 25px;
  position: relative;
  align-items: flex-start;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .terms-modal {
  position: absolute;
  top: 2%;
  right: 2%;
  background: white;
  border-radius: 11px;
  z-index: 9;
  left: 2%;
  bottom: 0;
  padding: 20px 15px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .terms-modal .close-modal-icon {
  position: absolute;
  top: -7px;
  right: 50%;
  cursor: pointer;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-caption {
  font-size: 61px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: 4.27px;
  text-align: center;
  color: white;
  margin-top: 40px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-caption.done {
  font-size: 48px;
  font-weight: 700;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-caption.hide {
  display: none;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .center-row {
  width: 55%;
  margin: 0 auto;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form {
  width: 100%;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form.mobile-view-hide {
  display: none;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form.mobile-done {
  /*display: none;*/
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap {
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .done-icon {
  margin: 0 10px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control-title {
  font-size: 33px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  margin-bottom: 5px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control-title.done {
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 25px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control.show-password {
  margin-top: 5px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control input[type='checkbox'] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .control-label {
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  color: white;
  width: max-content;
  cursor: pointer;
  margin-left: 5px;
  user-select: none;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .onboard-input {
  border-radius: 11px;
  font-size: 33px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: white;
  color: black;
  border: none;
  outline: none;
  transition: height 1s;
  -webkit-transition: height 1s;
  padding: 10px 40px;
  opacity: 0.6;
  width: 560px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control input[type="checkbox"] + div svg {
  fill: rgba(255, 255, 255, 0.87) !important;
  background: white;
  border-radius: 5px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control input[type="checkbox"] + div svg path {
  fill: #89d2c7 !important;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .react-tel-input {
  width: 240px;
  height: 40px;
  border-radius: 11px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .react-tel-input .flag-dropdown {
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .react-tel-input .form-control {
  height: 100%;
  border-radius: 11px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .react-tel-input .selected-flag {
  border-radius: 11px;
  border: none;
  background-color: transparent;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .react-tel-input input {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: white;
  color: black;
  border: none;
  outline: none;
  transition: height 1s;
  -webkit-transition: height 1s;
  padding: 5px 10px;
  opacity: 0.6;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .cb-icon {
  margin-left: 10px;
  display: flex;
  position: relative;
  cursor: pointer;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .onboard-phone-input {
  border-radius: 5px;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: #89d2c7;
  color: white;
  border: none;
  outline: none;
  transition: height 1s;
  -webkit-transition: height 1s;
  padding: 0 10px;
  height: 50px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .done-btn {
  width: 160px;
  height: 45px;
  border-radius: 12px;
  background-color: white;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.73;
  letter-spacing: normal;
  text-align: center;
  color: #747474;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 40px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control ::placeholder {
  font-size: 33px;
  color: black;
  opacity: 0.43;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .red-q-mark {
  position: absolute;
  top: 20px;
  right: 15px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .red-q-mark.agree {
  top: 1px;
  right: -40px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .red-q-mark.dob {
  right: -25px !important;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .red-q-mark.send-sms {
  right: 5px;
  top: 5px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .error-msg {
  position: absolute;
  top: 23px;
  right: -276px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  background-color: #b44d4d;
  opacity: 0.7;
  padding: 0 8px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .error-msg.dob {
  right: -205px !important;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .error-msg.agree {
  right: -345px !important;
  top: 1px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .error-msg.send-sms {
  top: 45px;
  right: unset;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .error-msg.password {
  right: -298px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .store-icon {
  cursor: pointer;
  margin: 15px 0;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .store-icon .store-link {
  text-decoration: none;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control-hint {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  margin-top: 10px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control-hint.error {
  color: white;
  background-color: #b44d4d;
  opacity: 0.7;
  padding: 0 7px;
  border-radius: 5px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .send-link {
  font-size: 28px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 15%;
  padding-left: 10px;
  padding-top: 10px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .send-confirm {
  width: 55%;
  background-color: #89d2c7;
  padding: 30px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .done-bg {
  background: url('https://wizecare.com/vt/ui/app/assets/images/patient-onboard-done-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 590px;
  height: 504px;
  margin-top: 15%;
  align-self: flex-start;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .done-txt {
  font-size: 24px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: white;
  margin-top: 20px;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .prefer-btn {
  height: 45px;
  margin-top: 40px;
  cursor: pointer;
  border-radius: 40px;
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .prefer-btn .arrow-circle {
  width: 35px;
  height: 35px;
  background-color: #ade1d9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form.desktop-done {
    padding: 0 !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form.desktop-done .item-col-wrap.control-wrap .control .done-btn {
    margin-top: 20px !important;
  }
}

@media (min-width: 1280px) and (max-height: 768px) {
  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .center-row {
    width: 66%;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form {
    padding: 10px 0;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .done-txt {
    margin-top: 5px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap {
    margin: 30px 0;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .red-q-mark.send-sms {
    top: 31px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .error-msg.send-sms {
    top: 135px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-caption {
    margin-top: 30px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .stepper {
    bottom: 7%;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .center-row {
    width: 78%;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-caption {
    margin-top: 20px !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-caption.done {
    margin-top: 0 !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form {
    padding: 0 !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .prefer-btn {
    margin-top: 12px;
    height: 36px;
    font-size: 14px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap {
    margin: 30px 0 !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .onboard-phone-input {
    font-size: 16px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .control-title.done {
    font-size: 28px !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control ::placeholder {
    font-size: 33px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .send-link {
    font-size: 24px;
    margin-right: 5px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .done-bg {
    width: 410px;
    height: 350px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .done-txt {
    margin-left: 22% !important;
    margin-right: 22% !important;
    margin-top: 30px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .patient-onboard-wizard-modal {
    border: 1px solid brown;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-caption {
    margin-top: 20px !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-caption.done {
    margin-top: 0 !important;
    font-size: 40px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form {
    padding: 0 !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap {
    margin: 30px 0 !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .onboard-input {
    width: 320px;
    font-size: 22px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .onboard-phone-input {
    font-size: 16px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .control-title.done {
    font-size: 28px !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control ::placeholder {
    font-size: 16px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .done-btn {
    width: 132px;
    height: 40px;
    font-size: 16px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .red-q-mark {
    top: 4px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .red-q-mark.dob {
    top: 13px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .error-msg {
    top: 7px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .error-msg.dob {
    top: 14px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .error-msg.agree {
    right: 18% !important;
    top: 38px !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control-title.done {
    font-size: 26px !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .send-link {
    font-size: 22px;
    width: 90%;
    margin-right: 5px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .done-bg {
    width: 300px;
    height: 256px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .done-txt {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 26px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .patient-onboard-wizard-modal {
    border: 1px solid pink;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step {
    margin-top: 0 !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-caption {
    font-size: 26px !important;
    letter-spacing: 3.27px;
    margin-top: 30px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form {
    padding: 0 !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form.mobile-done {
    display: flex;
    flex-direction: column;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form.desktop-done {
    display: none;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap {
    margin-top: 10px;
    margin-bottom: 35px;
    margin-left: 0;
    margin-right: 0;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .onboard-input {
    width: 300px;
    font-size: 28px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .error-msg {
    top: 52px;
    right: 53px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .error-msg.password {
    top: 74px !important;
    right: 15px !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .error-msg.dob {
    right: 24px !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .error-msg.agree {
    right: 37px !important;
    top: 25px !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .red-q-mark {
    top: 9px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .red-q-mark.agree {
    top: -1px !important;
    right: -33px !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control input + div label[style] {
    font-size: 16px !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .store-icon {
    margin: 0 0 15px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control-title.done {
    font-size: 22px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step {
    margin-top: 0 !important;
    margin-bottom: 50px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .terms-modal .close-modal-icon {
    right: 33%;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-caption {
    font-size: 32px !important;
    letter-spacing: 3.27px;
    margin-top: 30px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-caption.done {
    font-size: 70px !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form {
    padding: 0 !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form.mobile-done {
    display: flex;
    flex-direction: column;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form.desktop-done {
    display: none;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap {
    margin-top: 10px;
    margin-bottom: 25px;
    margin-left: 0;
    margin-right: 0;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .onboard-input {
    width: 300px;
    font-size: 28px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .error-msg {
    top: 70px;
    right: 53px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .error-msg.password {
    top: 74px !important;
    right: 15px !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .error-msg.dob {
    right: 48px !important;
    top: 50px !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .error-msg.agree {
    right: 18px !important;
    top: 69px !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .red-q-mark {
    top: 18px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .red-q-mark.agree {
    top: 37px;
    left: 5% !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .red-q-mark.dob {
    top: 10px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control input + div label[style] {
    font-size: 16px !important;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .store-icon {
    margin: 0 0 15px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control .cb-icon {
    left: 50%;
    position: absolute;
    top: 30px;
    transform: translate(-50%);
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control ::placeholder {
    font-size: 20px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control-title {
    font-size: 20px;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control-title.done {
    font-size: 36px;
    margin: 3% 5% 0 5%;
  }

  .patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .onboard-step .step-form .control-wrap .control-hint {
    font-size: 14px;
  }
}


.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .item-row .item-col-wrap.col-50 {
  width: 50%;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .item-row.onboard-step .done-step-body {
  display: flex;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .item-row.onboard-step .done-step-body .item-col-wrap > .item-row {
  display: flex;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .item-row.onboard-step .justify-content-center {
  justify-content: center;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .item-row.onboard-step .align-items-center {
  align-items: center;
}

.patient-onboard-wizard-modal .patient-onboard-wizard-modal-body .item-row.onboard-step .flex-direction-col {
  flex-direction: column;
}
