
.onboard-stepper {
  font-family: 'Heebo', sans-serif;
}

.onboard-stepper .item-row .item-col-wrap {
  display: flex;
  justify-content: center;
}

.onboard-stepper .next-step {
  width: 270px;
  height: 70px;
  border-radius: 11px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.73;
  letter-spacing: normal;
  text-align: center;
  color: #747474;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.onboard-stepper .step-circle-btn {
  width: 18px;
  height: 18px;
  border-radius: 12px;
  opacity: 0.57;
  background-color: #42bfb4;
  margin: 7px 2px;
}

.onboard-stepper .step-circle-btn.selected {
  background-color: white;
  opacity: 1;
}

@media (min-width: 320px) and (max-width: 480px) {
  .onboard-stepper .next-step {
    width: 146px;
    height: 42px;
    font-size: 20px;
  }
}
