.wc-system-alert-modal {
    z-index: 2010 !important;
    padding-top: 150px !important;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
}

.wc-system-alert-modal .wc-system-alert-modal-paper {
    border-radius: 11px !important;
    max-width: 292px;
    margin: auto !important;
    background-color: white;
    padding: 15px;
    position: relative;
    z-index: 2001;
    box-shadow: rgb(0 0 0 / 77%) -1px 0px 41px 20px;

}

.wc-system-alert-modal .wc-system-alert-modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /*background: rgba(0, 0, 0, 0.5);*/
    z-index: 2000;
    will-change: opacity;
    transform: translateX(0px);
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.wc-system-alert-modal .wc-system-alert-modal-paper .wc-system-alert-modal-body {
    display: flex;
    flex-direction: column;
    color: #3a3a3a;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    font-size: 18px;
    min-height: 300px;
}

.wc-system-alert-modal .wc-system-alert-modal-paper .wc-system-alert-modal-body .title {
    font-size: 22px;
    font-weight: 500;
}

.wc-system-alert-modal .wc-system-alert-modal-paper .wc-system-alert-modal-body .text .bold {
    display: inline-block;
    font-weight: 500;
}

.wc-system-alert-modal .wc-system-alert-modal-paper .wc-system-alert-modal-body .buttons {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-weight: 500;
}

.wc-system-alert-modal .wc-system-alert-modal-paper .wc-system-alert-modal-body .buttons .primary {
  color: #818181;
  margin-top: 10px;
  padding: 10px
}

.wc-system-alert-modal .wc-system-alert-modal-paper .wc-system-alert-modal-body .buttons .close-btn {
    color: #818181;
    margin-top: 10px;
    padding: 10px
}
